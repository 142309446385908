import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../../../components/Dashboard/DashboardWrapper';
import Layout from '../../../components/layout';
import { withAuthorization } from '../../../components/Session';
import HeadData from '../../../data/HeadData';
import BackButton from '../../../components/Common/BackButton';
import TRSectionNewUpdate from '../../../components/Sections/TRSectionNewUpdate';

const TRPageBase = () => (
  <DashboardWrapper pageName="Transactional Risk">
    <div className="relative pt-16 pb-32 md:pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <TRSectionNewUpdate />

        <BackButton buttonLink="/sales-support-documents/collateral" />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = (authUser) => !!authUser;

const TRPage = compose(withAuthorization(condition))(
  TRPageBase,
);

const TR = () => (
  <Layout>
    <HeadData title="Transactional Risk" />
    <TRPage />
  </Layout>
);

export default TR;
