import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components'
import WistiaVideo from './WistiaVideo';

const StyledDiv = styled.div`
  .react-tabs__tab-list {
    margin: 0px;
    border-bottom: 1px solid #bfb9b7;
  }
  .react-tabs__tab {
    font-weight: bold;
    background: #403E42;
    color: #fff;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #bfb9b7;
  }
  .react-tabs__tab--selected,
  .react-tabs__tab:hover {
    background: #b02929;
    color: #fff;
  }
  .react-tabs__tab-panel {
    background: #fff;
  }
`

const PortalVideoUpdateNew = ({
  videoID,
  videoTitle,
  withoutInfo,
  children,
  withButton,
  internalButton,
  buttonText,
  buttonUrl,
  withButton2,
  internalButton2,
  buttonText2,
  buttonUrl2
}) => {
  return (
    <div>
      <StyledDiv>
        <Tabs>
          <TabList>
            <Tab>Video</Tab>
            {withoutInfo
              ?
              null
              :
              <Tab>Information</Tab>
            }
          </TabList>

          <TabPanel>
            <div className='w-full rounded-md shadow-md'>
              
              <WistiaVideo wistiaID={videoID ? videoID : null} />

              {videoTitle
                ?
                <div className='p-4'>
                  <h2 className='text-red-900 text-lg md:text-xl font-bold truncate'>{videoTitle}</h2>
                </div>
                :
                null
              }
            </div>
          </TabPanel>
          {withoutInfo
            ?
            null
            :
            <TabPanel>
              <div className='p-4 xl:p-8 rounded-md shadow-md'>
                {videoTitle
                  ?
                  <h2 className='text-red-900 text-lg md:text-xl font-bold uppercase mb-6'>{videoTitle}</h2>
                  :
                  null
                }

                {children}

                {withButton
                  ?
                  internalButton
                    ?
                    <Link to={buttonUrl}>
                      <button className="w-full flex items-center justify-center self-end text-sm px-5 py-3 mt-6 border border-transparent lg:text-lg font-bold rounded-md text-white uppercase tracking-widest bg-red-800 hover:bg-red-900">
                        {buttonText}
                      </button>
                    </Link>
                    :
                    <a href={buttonUrl}>
                      <button className="w-full flex items-center justify-center self-end text-sm px-5 py-3 mt-6 border border-transparent lg:text-lg font-bold rounded-md text-white uppercase tracking-widest bg-red-800 hover:bg-red-900">
                        {buttonText}
                      </button>
                    </a>
                  :
                  null
                }

                {withButton2
                  ?
                  internalButton2
                    ?
                    <Link to={buttonUrl2}>
                      <button className="w-full flex items-center justify-center self-end text-sm px-5 py-3 mt-6 border border-transparent lg:text-lg font-bold rounded-md text-white uppercase tracking-widest bg-red-800 hover:bg-red-900">
                        {buttonText2}
                      </button>
                    </Link>
                    :
                    <a href={buttonUrl2}>
                      <button className="w-full flex items-center justify-center self-end text-sm px-5 py-3 mt-6 border border-transparent lg:text-lg font-bold rounded-md text-white uppercase tracking-widest bg-red-800 hover:bg-red-900">
                        {buttonText2}
                      </button>
                    </a>
                  :
                  null
                }
              </div>
            </TabPanel>
          }
        </Tabs>
      </StyledDiv>
    </div>
  )
}

PortalVideoUpdateNew.propTypes = {
  videoID: PropTypes.string,
  videoTitle: PropTypes.string,
  withoutInfo: PropTypes.bool,
  children: PropTypes.node,
  withButton: PropTypes.bool,
  internalButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  withButton2: PropTypes.bool,
  internalButton2: PropTypes.bool,
  buttonText2: PropTypes.string,
  buttonUrl2: PropTypes.string,
}

export default PortalVideoUpdateNew